import React, { useState } from 'react'
import styled from 'styled-components';



type MenuListType = {
  listName: string,
  listNum: number
}

type TabType = {
  menuList: MenuListType[],
  onClick: (index: number) => void,
}

export const Tab = ( { menuList, onClick }: TabType) => {
  const [selectNum, setSelectNum] = useState<number>(0);

  return (
      <TabWrapper>
          <TabContainer>
              {menuList.map((item, index) => (
                   <TabItem
                      key={item.listNum}
                      $selected={selectNum === item.listNum ? true : false}
                      onClick={() => { setSelectNum(item.listNum); onClick(item.listNum) }}>
                      {item.listName}
                  </TabItem>
              ))}
          </TabContainer>
      </TabWrapper>
  )
}

const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const TabContainer = styled.div`
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;

    @media (min-width: 1025px) {
      column-gap: 40px;
    }
`;

const TabItem = styled.span<{ $selected: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${({ $selected }) => $selected ? '#243763' : '#999999'};
  cursor: pointer;
  position: relative;

  ${({ $selected }) => $selected && `
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -12px;
      height: 2px;
      background-color: #243763;
    }
  `}

  @media (min-width: 1025px) {
    font-family: Pretendard;
    font-weight: 600;
    font-size: 18px;
    line-height: 27.9px;
  }

`;