import React, { useEffect, useState } from 'react'
import styled from 'styled-components';

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HeaderWrapper isScrolled={isScrolled}>
      <HeaderContainer>
        <HeaderLeft>
          {!isScrolled && <LogoImg src="assets/images/logo_white.png" alt="헤더 로고 이미지" />}
          {isScrolled && <LogoImg src="assets/images/logo.png" alt="헤더 로고 이미지" />}
        </HeaderLeft>
      </HeaderContainer>
    </HeaderWrapper>

  )
}
const HeaderWrapper = styled.header<{ isScrolled: boolean }>`
  position: fixed;
  width: 100%;
  height: 48px;
  z-index: 999;
  background-color: ${({ isScrolled }) => (isScrolled ? 'white' : 'transparent')};
  transition: background-color 0.2s ease-in-out;

  @media (min-width: 1025px) {
    height: 72px;
  }
`;

const HeaderLeft = styled.div`

`;

const HeaderContainer = styled.div`
  margin: 14px 24px 0 24px;
  @media (min-width: 1025px) {
    margin: 22px 0 0 120px;
    width: 1200px;
  }

`;

const LogoImg = styled.img`
  width: 112px;
  @media (min-width: 1025px) {
    width: 152px;
  }
`;