import React from 'react'
import styled from 'styled-components';

export const OurArchive = () => {
  return (
    <OurArchiveContainer>
      <TextWrapper>
        <Subject>OUR ARCHIVE</Subject>
        <Comment>에이디오트는 다양한 기업 활동 및 파트너십을 통해<br /> 꾸준한 성장을 이루고 있습니다</Comment>
      </TextWrapper>
      <ArchiveSlide>
        <Archive>
          <ArchiveTop>
            <div>2023.11</div>
            <span>한전 [건물 에너지 통합 관리 및 장치] 기술이전 계약 체결</span>
          </ArchiveTop>
          <ArchiveImg src='assets/images/archive_img_1.png'/>
        </Archive>
        <Archive>
          <ArchiveTop>
            <div>2024.02</div>
            <span>2024 에너지벨리 수도권 투자유치 로드쇼 시드머니 유치 협약</span>
          </ArchiveTop>
          <ArchiveImg src='assets/images/archive_img_2.png'/>
        </Archive>
        <Archive>
          <ArchiveTop>
            <div>2024.11</div>
            <span>초기관광벤처 최우수 장관상 수상</span>
          </ArchiveTop>
          <ArchiveImg src='assets/images/archive_img_3.png'/>
        </Archive>
      </ArchiveSlide>
    </OurArchiveContainer>
  )
}

const OurArchiveContainer = styled.div`
  background-color: #F6F6F6;
  padding: 120px 0;

  @media (min-width: 1025px) {
    padding: 160px 0;
  }
`;


const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
`;

const Subject = styled.span`
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  letter-spacing: 0.01em;

  @media (min-width: 1025px) {
    font-size: 56px;
    line-height: 70px;
    letter-spacing: -0.01em;
  }
  
`;

const Comment = styled.p`
  width: 312px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #4E565C;

  @media (min-width: 641px) {
    width: 582px;
  }

  @media (min-width: 1025px) {
    width: 700px;
    font-size: 18px;
    line-height: 27.9px;
    letter-spacing: -0.02em;
  }
`;

const ArchiveSlide = styled.div`
  display: flex;
  
  column-gap: 8px;
  margin-left: 24px;
  overflow-x: scroll;
  border-radius: 8px;
  @media (min-width: 1025px) {
    column-gap: 24px;
    margin-left: 0px;
    justify-content: center;
  }
`;

const Archive = styled.div`
  margin-top: 40px;
`;

const ArchiveTop = styled.div`
  position: relative;
  width: 312px;
  height: 136px;
  border-radius: 8px 8px 0 0;
  background-color: #FFFFFF;
  padding: 24px;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 24px;
    border-radius: 100px;
    background-color: #F0F2F5;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: #565656;
    @media (min-width: 1025px) {
      width: 83px;
      height: 26px;
      font-size: 14px;
    };

  };

  & > span {
    display: block;
    margin-top: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;

    @media (min-width: 1025px) {
      font-size: 18px;
    };
  }
  
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-top-left-radius: 8px; // 모서리 둥글게
    clip-path: polygon(0 0, 100% 0, 0 100%); // 삼각형 모양 만들기
    background-color: #2D447A;
    @media (min-width: 1025px) {
      width: 24px;
      height: 24px;
    };
  }

  @media (min-width: 1025px) {
    width: 384px;
    height: 158px;
  };
`;

const ArchiveImg = styled.img`
  display: blck;
  width: 312px;
  height: 160px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  @media (min-width: 1025px) {
    width: 384px;
    height: 240px;
  };
`;