import React from 'react'
import styled from 'styled-components';

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterBgImage src='assets/images/footer_bg.png' alt='푸터 백그라운드 이미지' />
      <FooterInfoWrapper>
        <FooterInfoContainer>
          <ContactUs>
            <span>CONTACT US</span>
            <p>
              에이디오트는 언제나 고객의 문제를 우선으로 고민합니다.<br />
              대표 전화 혹은 이메일로 문의주시면 확인하여 연락드리겠습니다.
            </p>
            <div>
              contact@adiot.co.kr
            </div>
          </ContactUs>
          <CompanyInfo>
            <img src='assets/images/footer_logo.png' alt='푸터 로고'></img>
            <div>
              <div>(주) 에이디오트  |  사업자번호 : 881-88-02079  |  대표이사 : 정민규</div>
              <div>주소 광주 북구 첨단과기로208번길 43-22 와이어스파크 B동 2201호 전화 062.975.0110 팩스 062.975.0415 메일 contact@adiot.co.kr</div>
              <div>Copyrightⓒ ADIoT All rights reserve</div>
            </div>
          </CompanyInfo>
        </FooterInfoContainer>
      </FooterInfoWrapper>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  position: relative;
`;

const FooterBgImage = styled.img`
  display: block;
  width: 100%;
  height: 760px;
  object-fit: cover;
`;

const FooterInfoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 120px 0px 80px 0px;
`;

const FooterInfoContainer = styled.div`
  
`;

const ContactUs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > span {
    font-weight: 700;
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: -0.01em;
    color: #ffffff;
  };

  & > p {
    width: 312px;
    margin-top: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #F4F4F4;
    text-align: center;

    @media (min-width: 641px) {
      width: auto;
    }
  };

  & > div {
    margin-top: 40px;
    width: 312px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-image-source: linear-gradient(115.67deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.05) 100%);
    border-radius: 8px;
    background: linear-gradient(136.02deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.08) 100%);
    backdrop-filter: blur(80px);
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    line-height: 26.1px;
    letter-spacing: -0.02em;
    text-decoration: underline;
    text-underline-offset: 3px;

    @media (min-width: 641px) {
      width: 384px;
    }
  };
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    margin-top: 120px;
    width: 180px;
  };

  & > div {
    width: 312px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 24px;
    text-align: center;

    & > div {
      color: #CCCCCC;
      letter-spacing: -0.02em;
      line-height: 16.2px;
      font-weight: 400;
      font-size: 12px;
    }

    @media (min-width: 641px) {
      width: auto;
    }
  }
`;