import React from 'react';
import styled from 'styled-components';

const Table = styled.table`
  width: 328px;
  border-collapse: collapse;
  margin: 24px auto;

  @media (min-width: 641px) {
    width: 609px;
  }

  @media (min-width: 1025px) {
    width: 1200px;
    margin-top: 48px;
  }
`;

const Th = styled.th`
  border-top: 1px solid #243763;
  border-bottom: 1px solid #243763;
  background-color: #F0F2F5;
  padding: 8px 0;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16.2px;
  letter-spacing: -0.02em;

  @media (min-width: 1025px) {
    font-size: 16px;
    line-height: 24px;
  }
  
`;


const Td = styled.td<{ $isBorderBottom?: boolean }>`
  min-width: 72px;
  border-bottom: 1px solid ${({ $isBorderBottom }) => $isBorderBottom ? '#243763' : '#e0e0e0'};
  padding: 10px 0;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.2px;
  letter-spacing: -0.02em;

  @media (min-width: 1025px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const EnterpriseCertificateTable = () => {
  return (
    <Table>
      <thead>
        <tr>
          <Th>분류</Th>
          <Th>명칭</Th>
          <Th>등록번호</Th>
          <Th>등록일자</Th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Td rowSpan={4} $isBorderBottom={true}>기업인증</Td>
          <Td>벤처기업 확인서</Td>
          <Td>20231213030097</Td>
          <Td>2024.02.29</Td>
        </tr>
        <tr>
          <Td>기술혁신형 중소기업(Inno-Biz) 확인서</Td>
          <Td>240403-00486</Td>
          <Td>2024.05.16</Td>
        </tr>
        <tr>
          <Td>경영혁신형 중소기업(MAIN-BIZ)확인서</Td>
          <Td>240404-08544</Td>
          <Td>2024.07.11</Td>
        </tr>
        <tr>
          <Td $isBorderBottom={true}>에너지특화기업 지정서</Td>
          <Td $isBorderBottom={true}>2024-39</Td>
          <Td $isBorderBottom={true}>2024.12.31</Td>
        </tr>
      </tbody>
    </Table>
  );
};

const CopyRightTable = () => {
  return (
    <Table>
      <thead>
        <tr>
          <Th>분류</Th>
          <Th>명칭</Th>
          <Th>등록번호</Th>
          <Th>등록일자</Th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Td rowSpan={4} $isBorderBottom={true}>특허 출원</Td>
          <Td>수요전력 예측을 이용한 전력피크 저감시스템 및 방법</Td>
          <Td>10-2017-0174265</Td>
          <Td>2019.11.26</Td>
        </tr>
        <tr>
          <Td>퍼스널 모빌리티 기반의 컨텐츠 제공 시스템 및 방법</Td>
          <Td>10-2021-0060233</Td>
          <Td>2021.05.10</Td>
        </tr>
        <tr>
          <Td>퍼스널 모빌리티 기반의 사용자 맞춤형 광고 시스템 및 방법</Td>
          <Td>10-2021-0060236</Td>
          <Td>2021.05.10</Td>
        </tr>
        <tr>
          <Td $isBorderBottom={true}>퍼스널 모빌리티 기반의 컨텐츠 투자 장치</Td>
          <Td $isBorderBottom={true}>10-2021-0069159</Td>
          <Td $isBorderBottom={true}>2021.11.30</Td>
        </tr>
        <tr>
          <Td rowSpan={2} $isBorderBottom={true}>저작권 등록</Td>
          <Td>건물 쾌적상태 분석 대시보드</Td>
          <Td>C-2024-002496</Td>
          <Td>2024.01.16</Td>
        </tr>
        <tr>
          <Td $isBorderBottom={true}>적설 AI 시설 상태 테스트 뷰</Td>
          <Td $isBorderBottom={true}>C-2024-038053</Td>
          <Td $isBorderBottom={true}>2024.10.21</Td>
        </tr>
        <tr>
          <Td rowSpan={4} $isBorderBottom={true}>특허출원</Td>
          <Td>멀티 에이전트를 이용한 건물 에너지 관리 방법</Td>
          <Td>2023-0146092</Td>
          <Td>2023.10.17</Td>
        </tr>
        <tr>
          <Td>최적수익 조정이 가능한 AI 기반 건물 에너지 관리 시스템</Td>
          <Td>2023-0146093</Td>
          <Td>2023.10.27</Td>
        </tr>
        <tr>
          <Td>고품질 학습데이터에 의해 검증되는 인공지능 모델 기반의 건물 관리 방법</Td>
          <Td>2024-0124829</Td>
          <Td>2024.01.30</Td>
        </tr>
        <tr>
          <Td $isBorderBottom={true}>재실감지를 통해 계절적 전력 소비량을 최소화하는 AIoT 시스템</Td>
          <Td $isBorderBottom={true}>2024-0124829</Td>
          <Td $isBorderBottom={true}>2024.09.23</Td>
        </tr>
      </tbody>
    </Table>

  );
};

export {CopyRightTable,EnterpriseCertificateTable};


