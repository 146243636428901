import React, { useState } from 'react'
import styled from 'styled-components';

export const BusinessArea = () => {
  const [hoverIndex, setHoverIndex] = useState<number | undefined>();

  const handleMouseOver = (index: number) => {
    setHoverIndex(index);
  };

  const handleMouseOut = () => {
    setHoverIndex(undefined);
  };
  return (
    <BusinessAreaContainer>
      <TextWrapper>
        <Subject>BUSINESS AREA</Subject>
        <Comment>에이디오트는 최고의 기술력과 책임감을 가지고 IT 인프라 구축사업 및 에너지 사업을 진행하고 있으며 고객의 요구에 부합하는 최적의 서비스를 제공해드릴 것을 약속합니다</Comment>
      </TextWrapper>
      <ImageSlider>
        <SlidImageWrapper
          onMouseOver={() => handleMouseOver(0)}
          onMouseOut={handleMouseOut}>
          <SlidImage src='assets/images/business_area_slide_1.png' alt='슬라이드 이미지 1' />
          <SlidImageSubject>System Consulting</SlidImageSubject>
          {/* <Hover $isHover={hoverIndex === 0}>

          </Hover> */}
          <Hover>
            <div>
              <div>ㆍIT 인프라 구축 및 최적화</div>
              <div>ㆍ맞춤형 소프트웨어 개발 및 구현</div>
              <div>ㆍIT 전략 수립 및 컨설팅 서비스 제공</div>
            </div>
          </Hover>
        </SlidImageWrapper>
        <SlidImageWrapper
          onMouseOver={() => handleMouseOver(1)}
          onMouseOut={handleMouseOut}>
          <SlidImage src='assets/images/business_area_slide_2.png' alt='슬라이드 이미지 2' />
          <SlidImageSubject>AI Data Service</SlidImageSubject>
          <Hover>
            <div>
              <div>ㆍ빅데이터 분석 및 인사이트 도출</div>
              <div>ㆍ인공지능(AI) 및 머신러닝 솔루션 개발</div>
              <div>ㆍ데이터 보안 및 백업 서비스</div>
            </div>
          </Hover>
        </SlidImageWrapper>
        <SlidImageWrapper
          onMouseOver={() => handleMouseOver(2)}
          onMouseOut={handleMouseOut}>
          <SlidImage src='assets/images/business_area_slide_3.png' alt='슬라이드 이미지 3' />
          <SlidImageSubject>BEMS</SlidImageSubject>
          <Hover>
            <div>
              <div>ㆍ실시간 에너지 사용 모니터링 및 분석</div>
              <div>ㆍHVAC, 조명, 보안 등 건물 시스템 최적화</div>
              <div>ㆍ에너지 효율 개선 및 비용 절감 솔루션 제공</div>
            </div>
          </Hover>
        </SlidImageWrapper>
      </ImageSlider>

    </BusinessAreaContainer>
  )
}

const BusinessAreaContainer = styled.section`
  display: flex;
  row-gap: 40px;
  flex-direction: column;
  justify-content: center;
  height: 651px;

  @media (min-width: 641px) {
    height: 658px;
  }

  @media (min-width: 1025px) {
    height: 870px;
    row-gap: 80px;
    align-items: center;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
`;

const Subject = styled.span`
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  letter-spacing: 0.01em;

  @media (min-width: 1025px) {
    font-size: 56px;
    line-height: 70px;
    letter-spacing: -0.01em;
  }
`;

const Comment = styled.p`
  width: 312px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: center;

  @media (min-width: 641px) {
    width: 582px;
  }

  @media (min-width: 1025px) {
    width: 700px;
    font-size: 18px;
    line-height: 27.9px;
    letter-spacing: -0.02em;
  }
`;

const ImageSlider = styled.div`
  display: flex;
  column-gap: 16px;
  margin-left: 24px;
  overflow-x: scroll;
  
  @media (min-width: 1025px) {
    column-gap: 24px;
    margin-left: 0px;
  }
`;

const SlidImage = styled.img`
  display: block;
  width: 280px;
  height: 234px;
  border-radius: 8px;

  @media (min-width: 641px) {
    width: 314px;
    height: 262px;
  }

  @media (min-width: 1025px) {
    width: 384px;
    height: 320px;
  }
`
const Hover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  background-color: #1389B8;
  border-radius: 8px;
  overflow: hidden;
  & > div {
    position: absolute;
    top: 170px;
    left: 16px;
    
    & > div {
      
      font-weight: 400;
      font-size: 70px;
      line-height: 16.2px;
      letter-spacing: -0.01em;
      transition: font-size 0.2s ease-in-out;
      color: #EBEBEB;
    }
  }

`;

const SlidImageSubject = styled.span`
  position: absolute;
  color: #ffffff;
  left: 18px;
  bottom: 18px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.02em;
  transition: bottom 0.2s ease-in-out;

  @media (min-width: 1025px) {
    font-size: 24px;
  }
`;

const SlidImageWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  
  &:hover ${Hover} {
    opacity: 90%;
    visibility: visible;
    & > div {
      top: 170px;
      left: 16px;
      & > div  {
        font-weight: 400;
        font-size: 12px;
        line-height: 16.2px;
        letter-spacing: -0.01em;
        @media (min-width: 1025px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      @media (min-width: 641px) {
        top: 190px;
      }

      @media (min-width: 1025px) {
        top: 224px;
      }
    }
  };

  &:hover ${SlidImageSubject} {
    bottom: 72px;
    z-index: 999;

    @media (min-width: 641px) {
      bottom: 80px;
    }

    @media (min-width: 1025px) {
      bottom: 112px;
    }
  };
`;
