import React, { useState } from 'react'
import styled from 'styled-components';

type Functioninfo = {
  title: string,
  img: string
}
const funtionInfo:Functioninfo[] = [
  {title: '경보 관제 및 목표 소비량 확인', img: 'funtion_exam_1.png'},
  {title: '에너지 사용 현황 조회', img: 'funtion_exam_2.png'},
  {title: '에너지 분석 및 예측', img: 'funtion_exam_3.png'},
  {title: '설비 운전 관리 및 제어', img: 'funtion_exam_4.png'},
]

export const SolutionArea = () => {
  const [selectFuntionInfo, setSelectFuntionInfo] = useState<Functioninfo>(funtionInfo[0]);

  const handleFuntionInfo = (type: 'back'|'forward') => {
    setSelectFuntionInfo((prev) => {
      const currentIndex = funtionInfo.findIndex(info => info.title === prev.title);
      let newIndex;
      if (type === 'back') {
        newIndex = (currentIndex - 1 + funtionInfo.length) % funtionInfo.length;
      } else {
        newIndex = (currentIndex + 1) % funtionInfo.length;
      }
      return funtionInfo[newIndex];
    });
  }

  return (
    <SolutionAreaContainer>
      <div>
        <TextWrapper>
          <Subject>BEMS SOLUTION</Subject>
          <Comment>AD-BEMS는 건물 에너지 사용을 실시간으로 모니터링하고 데이터 분석을 통해 최적의 환경을 제공하며 에너지를 효율적으로 관리하는 에너지 통합 관리 시스템입니다</Comment>
        </TextWrapper>
        <SolutionInfoImage src='assets/images/solution_info_wide.png' alt='솔루션 정보 이미지' />
      </div>
      <div>
        <FuntionTitle>주요기능</FuntionTitle>
        <FuntionComment>
          ICT와 AI 기술을 융합하여 더욱 효율적인 시스템을 제공합니다
        </FuntionComment>
        <FuntionExam>
          <div>
            <span>{selectFuntionInfo.title}</span>
            <div>
              <ButtonImg src='assets/images/button_back.png' onClick={()=>{handleFuntionInfo('back')}}/>
              <ButtonImg src='assets/images/button_forward.png' onClick={()=>{handleFuntionInfo('forward')}}/>
            </div>
          </div>
          <FuntionImg src={`assets/images/${selectFuntionInfo.img}`}/>
        </FuntionExam>
      </div>
      <div>
        <FuntionTitle>도입 및 운영</FuntionTitle>
        <FuntionComment>
          운영중인 건물에 적합하도록 맞춤형 시스템 구축을 단계별로 진행합니다
        </FuntionComment>
        <StepContainer>
          <StepBox>
            <div>
              <StepBoxTopLeft>
                <span>
                  STEP1
                </span>
                <span>
                  계획 및 설계
                </span>
              </StepBoxTopLeft>
              <img src="assets/images/solution_step_1.png" alt="" />
            </div>
            <div>
              <Step1>
                <span>
                  ① 건물 운영 상태 확인
                </span>
                <span>
                  건물 용도, 내부 설비 및 운영 현황을 종합적으로 파악합니다.
                </span>
              </Step1>
              <Step1>
                <span>
                  ② 에너지 및 성능 관련 요소 파악
                </span>
                <span>
                  건물 특성 (용도, 설비)과 경제성, 적용 가능성을 고려하여 최적의 요소를 파악합니다.
                </span>
              </Step1>
              <Step1>
                <span>
                  ③ 시스템 구축 수준 선정
                </span>
                <span>
                  BEMS 구축 수준을 정하고 이에 따라 필요한 장비 선정 및 설계를 진행합니다.
                </span>
              </Step1>
            </div>
          </StepBox>
          <StepBox>
            <div>
              <StepBoxTopLeft>
                <span>
                  STEP2
                </span>
                <span>
                  구축 및 운영
                </span>
              </StepBoxTopLeft>
              <img src="assets/images/solution_step_2.png" alt="" />
            </div>
            <div>
              <Step2>
                <span>
                  ④ BEMS 구축
                </span>
                <span>
                  BEMS 설계에 따라 시스템을 구성하고 평가 항목에 맞춰 데이터를 시각화합니다.
                </span>
              </Step2>
              <Step2>
                <span>
                  ⑤ 데이터 수집 및 분석
                </span>
                <span>
                  각종 센서로부터 수집된 데이터를 기반으로 절감 방법을 심층 분석합니다.
                </span>
              </Step2>
              <Step2>
                <span>
                  ⑥ 에너지 절감 방안 제시
                </span>
                <span>
                  분석 결과를 바탕으로 에너지 효율 운영을 제시 및 BEMS 운영에 반영합니다.
                </span>
              </Step2>
            </div>
          </StepBox>
        </StepContainer>

      </div>
    </SolutionAreaContainer>
  )
}


const SolutionAreaContainer = styled.div`
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 120px;
  background-color: #F0F4FA;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
`;

const Subject = styled.span`
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  letter-spacing: -0.01em;

  @media (min-width: 1025px) {
    font-size: 56px;
    line-height: 70px;
    letter-spacing: -0.01em;
  }
`;

const Comment = styled.p`
  width: 312px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #4E565C;

  @media (min-width: 641px) {
    width: 565px;
  }

  @media (min-width: 1025px) {
    width: 710px;
    font-size: 18px;
    line-height: 27.9px;
    letter-spacing: -0.02em;
  }
`;

const SolutionInfoImage = styled.img`
  display: block;
  width: 312px;
  height: 160px;
  margin-top: 48px;
  border-radius: 8px;

  @media (min-width: 641px) {
    width: 593px;
    height: 240px;
  }

  @media (min-width: 1025px) {
    width: 1200px;
    height: 320px;
  }
`;

const FuntionTitle = styled.span`
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #1286B3;

  @media (min-width: 1025px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 26.1px;
  }
`;
const FuntionComment = styled.p`
  width: 312px;
  margin-top: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;

  @media (min-width: 641px) {
    width: auto;
  }
  @media (min-width: 1025px) {
    font-weight: 600;
    font-size: 32px;
    line-height: 41.6px;
  }
`;

const FuntionExam = styled.div`
  margin: auto;
  margin-top: 40px;
  width: 312px;
  height: 211.66px;
  border-radius: 8px;
  background-color: #E1E5F0;
  padding: 24px;

  & > div {
    display: flex;
    justify-content: space-between;
    & > span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      @media (min-width: 1025px) {
        font-weight: 600;
        font-size: 22px;
        line-height: 31.9px;
        letter-spacing: -0.02%;
      }

    }
    & > div {
      display: flex;
      justify-content: center;
      column-gap: 8px;
    }


    @media (min-width: 1025px) {
      width: 1040px;
      margin: 0 auto;
    }
  }

  @media (min-width: 641px) {
    width: 593px;
    height: 343.29px;
  }

  @media (min-width: 1025px) {
    width: 1200px;
    padding: 40px;
    height: 640px;
  }
`;

const FuntionImg = styled.img`
  display: block;
  margin-top: 16px;
  width: 264px;
  border: 1px solid #485878;
  border-radius: 4px;

  @media (min-width: 641px) {
    width: 545px;
  }

  @media (min-width: 1025px) {
    width: 1040px;
    margin: 24px auto 0 auto;
    border: 1.5px solid #485878;
  }
`;

const ButtonImg = styled.img`
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  @media (min-width: 1025px) {
    width: 32px;
    height: 32px;
  }
`;

const StepContainer = styled.div`
  margin-top: 40px;
  width: 312px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  @media (min-width: 641px) {
    width: auto;
  }
`;

const StepBox = styled.div`
  width: 312px;
  @media (min-width: 1025px) {
    width: 588px;
  }
  & > div:nth-child(1) {
    height: 96px;
    background-color: #6B768F;
    border-radius: 8px 8px 0 0;
    padding: 21px 24px;
    display: flex;
    justify-content: space-between;

    @media (min-width: 1025px) {
      padding: 22px 24px;
      height: 104px;
    }
  };

  & > div:nth-child(2){
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    height: 292px;
    padding: 24px;
    border-radius: 0 0 8px 8px;
    background-color: #ffffff;
    @media (min-width: 1025px) {
      height: 250px;
    }
  };

  & img {
    width: 64px;
    height: 64px;
  }
`

const StepBoxTopLeft = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  
  & > span {
    display: block;
    color: #ffffff;
    font-weight: 700;
    &:nth-child(1) {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;

      @media (min-width: 1025px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &:nth-child(2) {

      font-size: 18px;
      line-height: 26.1px;
      letter-spacing: -0.01em;
      @media (min-width: 1025px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
`;

const Step1 = styled.div`
  & > span {
    display: block;
    &:nth-child(1) {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #4B65F0;
      @media (min-width: 1025px) {
        font-size: 18px;
        line-height: 26.1px;
      }
    };
    &:nth-child(2) {
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #4E565C;
      @media (min-width: 1025px) {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
      }
    }
  }
`;

const Step2 = styled.div`
  & > span {
    display: block;
    &:nth-child(1) {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #289FC7;
      @media (min-width: 1025px) {
        font-size: 18px;
        line-height: 26.1px;
      }
    };
    &:nth-child(2) {
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #4E565C;
      @media (min-width: 1025px) {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
      }
    }
  }
`;