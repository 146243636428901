import React from 'react'
import styled from 'styled-components';
import { Header } from '../components/layout/Header';
import { Footer } from '../components/layout/Footer';

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout = ( {children} : MainLayoutProps) => {
  return (
    <MainLayoutWrapper>
      <Header />
      <ContentContainer>
        {children}
      </ContentContainer>
      <Footer />
    </MainLayoutWrapper>
  )
}

const MainLayoutWrapper = styled.div`
  width: 100%;

`;

const ContentContainer = styled.div`

`;