import React, { useState } from 'react'
import styled from 'styled-components';
import { Tab } from '../common/Tab';
import { CopyRightTable, EnterpriseCertificateTable } from './CertificationTable';


const tabName = [
  { listName: "저작권 및 특허", listNum: 0 },
  { listName: "기업인증", listNum: 1 }
]

export const Certification = () => {
  const [activeContent, setActiveContent] = useState(0);


  const changeContent = (index: number) => {
    setActiveContent(index);
  }
  return (
    <CertificationContainer>
      <TextWrapper>
        <Subject>CERTIFICATION</Subject>
        <Comment>에이디오트는 업계 표준 인증을 기반으로<br />최적화된 솔루션을 제공합니다</Comment>
      </TextWrapper>
      <CertificationContent>
        <Tab menuList={tabName} onClick={changeContent}></Tab>
        {
          activeContent === 0 && <CopyRight />
        }
        {
          activeContent === 1 && <EnterpriseCertifica />
        }
      </CertificationContent>
    </CertificationContainer>
  )
}

const CopyRight = () => {
  return (
    <ContentContainer>
      <CertificateContainer>
        <Certificate src='assets/images/patent_certificate_1.png' />
        <Certificate src='assets/images/patent_certificate_2.png' />
        <Certificate src='assets/images/patent_certificate_3.png' />
        <Certificate src='assets/images/patent_certificate_4.png' />
      </CertificateContainer>
      <CopyRightTable />
    </ContentContainer>
  )
}

const EnterpriseCertifica = () => {
  return (
    <ContentContainer>
    <CertificateContainer>
      <Certificate src='assets/images/enterprise_certificate_1.png' />
      <Certificate src='assets/images/enterprise_certificate_2.png' />
      <Certificate src='assets/images/enterprise_certificate_3.png' />
      <Certificate src='assets/images/enterprise_certificate_4.png' />
    </CertificateContainer>
    <EnterpriseCertificateTable />
  </ContentContainer>
  )
}

const CertificationContainer = styled.div`
  padding: 120px 0;

  @media (min-width: 1025px) {
    padding: 160px 0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
`;

const Subject = styled.span`
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  letter-spacing: 0.01em;

  @media (min-width: 1025px) {
    font-size: 56px;
    line-height: 70px;
    letter-spacing: -0.01em;
  }
`;

const Comment = styled.p`
  font-family: Pretendard;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.45em;
  letter-spacing: -0.01em;
  text-align: center;

  @media (min-width: 641px) {
    width: 582px;
  }

  @media (min-width: 1025px) {
    width: 700px;
    font-size: 18px;
    line-height: 1.55em;
    letter-spacing: -0.02em;
  }
`;

const CertificationContent = styled.div`
  margin-top: 48px;

  @media (min-width: 1025px) {
    margin-top: 80px;
  }
`;

const ContentContainer = styled.div`
  margin-top: 24px;

  @media (min-width: 1025px) {
    margin-top: 48px;
  }
`;

const CertificateContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 8px;

  @media (min-width: 641px) {
    column-gap: 16px;
  }
  @media (min-width: 1025px) {
    column-gap: 24px;
  }
`;

const Certificate = styled.img`
  display: block;
  width: 76px;

  @media (min-width: 641px) {
    width: 140px;
  }
  @media (min-width: 1025px) {
    width: 282px;
  }
`
