import React from 'react'
import styled from 'styled-components';

export const SubBanner = () => {
  return (
    <SubBannerContainer>
      <BannerImg src='assets/images/sub_bg.png' alt='서브 배너'/>
      <TextWrapper>
        <Subject>
          에너지 분야의 혁신을 선도하고<br />
          핵심 기술을 공급하는 에너지 기업
        </Subject>
      </TextWrapper>
    </SubBannerContainer>
  )
}

const SubBannerContainer = styled.div`
  position: relative;
`;

const BannerImg = styled.img`
  display: block;
  width: 100%;
  height: 144px;
  object-fit: cover;
  transition: height 0.5s ease-in-out;

  @media (min-width: 1025px) {
    height: 288px;
  }

`;

const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Subject = styled.p`
  width: 312px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.16px;
  letter-spacing: -0.01em;
  text-shadow: 0px 2px 4px #00000040;
  text-align: center;

  @media (min-width: 1025px) {
    width: 650px;
    font-size: 48px;
    line-height: 64.32px;
  }
`;