import React from 'react'
import styled from 'styled-components';

export const MainBanner = () => {

  const subject = '건물의 가치를 높이는 최고의 선택';
  const comment = '에이디오트는 기존 에너지 시스템의 한계를 넘어 더 나은 미래를 위한 혁신적인 솔루션을 제시하는 에너지 플랫폼을 연구합니다'
  return (
    <MainBannerContainer>
      <BannerImg src='assets/images/main_bg.png' />
      <TextWrapper>
        <Subject>{subject}</Subject>
        <Comment>{comment}</Comment>
      </TextWrapper>
    </MainBannerContainer>
  )
}


const MainBannerContainer = styled.div`
  position: relative;
  width: 100%;
`;

const BannerImg = styled.img`
  display: block; //inline 요소는 아래쪽에 작은 여백(whitespace)인 descender space가 자동으로 추가됨.
  width: 100%;
  height: 484px;
  object-fit: cover;
  transition: height 0.5s ease-in-out;

  @media (min-width: 641px) {
    height: 404px;
  }

  @media (min-width: 1025px) {
    height: 714px;
  }

`;


const TextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);

  @media (min-width: 1025px) {
    left: 120px;
  }

`;

const Subject = styled.p`
  width: 264px;
  color: white;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;

  @media (min-width: 1025px) {
    width: 530px;
    line-height: 76.8px;
    font-size: 64px;
  }
`;

const Comment = styled.p`
  width: 264px;
  color: #A9BBC7;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.02em;

  @media (min-width: 1025px) {
    width: 530px;
    line-height: 76.8px;
    font-size: 20px;
    line-height: 28px;
  }
`;