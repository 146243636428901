import React from 'react'
import { MainLayout } from '../layouts/MainLayout'
import { MainBanner } from '../components/ui/MainBanner'
import { BusinessArea } from '../components/ui/BusinessArea'
import { SubBanner } from '../components/ui/SubBanner'
import { SolutionArea } from '../components/ui/SolutionArea'
import { OurArchive } from '../components/ui/OurArchive'
import { Certification } from '../components/ui/Certification'

export const Main = () => (
  <MainLayout>
    <MainBanner />
    <BusinessArea />
    <SubBanner />
    <SolutionArea />
    <Certification />
    <OurArchive />
  </MainLayout>
)